<template>
  <div>
    <template
      v-for="(component, index) in components"
      :key="`component_${index}`"
    >
      <!-- <component
        :is="getComponent(component.name, component.type.identifier)"
        :data="component.content"
        :block-data="component?.block_type_content"
        :name="component.type.identifier"
        :block-name="component.name"
        :plp-type="plpType"
        :block-index="index"
        @onPageUpdate="$emit('onPageUpdate', $event)"
      /> -->
      <component
        :is="getComponent(component.name, component.type.identifier)"
        v-if="component.currentTranslation?.content"
        :data="component.currentTranslation.content"
        :block-data="component?.block_type_content"
        :name="component.type.identifier"
        :block-name="component.name"
        :plp-type="plpType"
        :block-index="index"
        :page-data="pageData"
        @onPageUpdate="$emit('onPageUpdate', $event)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { BlockData, AllBlockContents, PageData } from "ecom-types";
import { plpTypes } from "@/configs";

const { getComponent } = useBlockContainer();

withDefaults(
  defineProps<{
    components: BlockData<AllBlockContents>[];
    plpType?: string;
    pageData: PageData | null;
  }>(),
  {
    components: () => [],
    plpType: plpTypes.default,
    pageData: null,
  },
);

const { scrollDepthEvent } = useEvents();

const { observerOnMounted, observerOnBeforeUnmount } =
  intersectionObserverInit(scrollDepthEvent);

onMounted(() => {
  observerOnMounted();
});

onBeforeUnmount(() => {
  observerOnBeforeUnmount();
});
</script>
